import $ from "jquery";
import slick from "slick-carousel";

const vcbSlider = {
    elem: {
        $slider: $(".js-dssSlider, .js-print-cards"),
        sliderOn: 1024,
        prevBtn: '<div class="slick-arrow slick-arrow_prev"><i class="chevron-right"></i></div>',
        nextBtn: '<div class="slick-arrow slick-arrow_next"><i class="chevron-left"></i></div>',
    },
    vcbResize: function(){
        if(vcbSlider.elem.$slider.length){
            // console.log('dsfdf');
            $(window).on('resize', function () {
                if (window.innerWidth < vcbSlider.elem.sliderOn) {
                    if (!vcbSlider.elem.$slider.hasClass("slick-initialized")) {
                        vcbSlider.mobileOnlySlider();
                    }
                } else {
                    if (vcbSlider.elem.$slider.hasClass("slick-initialized")) {
                        vcbSlider.elem.$slider.slick("unslick");
                    }
                }
            });

        }
    },
    mobileOnlySlider: function(){
        vcbSlider.elem.$slider.slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
            centerMode: true,
            infinite: true,
            prevArrow: vcbSlider.elem.prevBtn,
            nextArrow: vcbSlider.elem.nextBtn,
            // appendArrows: $('#videoSliderArrows'),

            responsive: [
                {
                  breakpoint: 767,
                  settings: {
                      centerPadding: '30px'
                    // slidesToShow: 1,
                    // slidesToScroll: 1,
                    // initialSlide: 1,
                    // centerMode: true,
                    // infinite: true,
                  }
                },
            ]
        });
    }
}

if (window.innerWidth < vcbSlider.elem.sliderOn) {
    vcbSlider.mobileOnlySlider();
}
$(window).on('resize', vcbSlider.vcbResize())