// import './components/hello';
// import  './components/scrollspy';
// import './components/formComponent';
// import textFit from 'textfit';
import './components/slider'
import { isMobile } from "./components/is-mobile";
import AOS from 'aos';
import SmoothScrollMagic from './components/smoothScroll';

// Main js file
let isTouch = false;
if ('ontouchstart' in document.documentElement) {
    isTouch = true;
}
document.documentElement.className += isTouch ? ' touch' : ' no-touch';
document.documentElement.className += isMobile() ? ' mobile' : ' desktop';

// const mainWrap = document.querySelector('main');

AOS.init({
    anchorPlacement: 'center-bottom',
    duration: 500,
    mirror: true
    // once: true,
});
let smoothScrollAllPage = new SmoothScrollMagic;
smoothScrollAllPage.init();


function scrollTo(element) {
    window.scroll({
      behavior: 'smooth',
      left: 0,
      top: element.getBoundingClientRect().top +  window.scrollY
    });
  }

const smoothLink = document.querySelectorAll('a[href*="#"]:not([href="#"])');

if(smoothLink.length > 0){
    [...smoothLink].forEach(e=>{
        const target = document.querySelector(e.hash);
        if(!!target){
            e.addEventListener('click', (event) => {
                event.preventDefault();
                scrollTo(target);
            });
        }
    })
}


// function toggle(selector,cls){
//     let target = document.querySelectorAll(selector);
//     if(target){
//         target.forEach(text => {
//             text.addEventListener('click', function(){
//                 this.classList.toggle(cls);
//             })
//         })
//     }
// }
//
// toggle('.header__trigger','is-open');


//example footer height detect
// function _getAbsoluteHeight(el) {
//     el = (typeof el === 'string') ? document.querySelector(el) : el;
//     var styles = window.getComputedStyle(el);
//     var margin = parseFloat(styles['marginTop']) +
//                  parseFloat(styles['marginBottom']);
//     return Math.ceil(el.offsetHeight + margin);
// }

// //footer height variable
// function setVariableFooterHeight(){
//     setTimeout(() => {
//         const footerH = _getAbsoluteHeight('.footer');

//         if(footerH !== 0){
//             document.documentElement.style.setProperty('--footerHeight', footerH + 'px');
//         }

//     }, 200);
// }

// setVariableFooterHeight();

// window.addEventListener("orientationchange", function() {
//     setVariableFooterHeight();
// });